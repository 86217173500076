import React, { useEffect, useState } from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import axios from 'axios'
import { convertSecondsToMinutes } from '../utils/secondsToMinutes'

const UsageStatistics = () => {
  const [usageStats, setUsageStats] = useState({
    recordingSeconds: 0,
    uploadSeconds: 0,
    maxAllowedLimit: 0,
  })

  useEffect(() => {
    const fetchUsageStats = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/usage`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('reekapToken')}`,
            },
          }
        )

        const data = await response.data

        const { recordingSeconds, uploadSeconds, allowedLimit } = data.data

        setUsageStats({
          recordingSeconds: recordingSeconds || 0,
          uploadSeconds: uploadSeconds || 0,
          maxAllowedLimit: allowedLimit,
        })
      } catch (error) {
        console.error('Error fetching usage statistics:', error)
        // Set default values or show an error message to the user
        setUsageStats({
          recordingSeconds: 0,
          uploadSeconds: 0,
          maxAllowedLimit: 0,
        })
      }
    }

    fetchUsageStats()
  }, [])

  const totalUsage = usageStats.recordingSeconds + usageStats.uploadSeconds
  const totalUsagePercentage = Math.round(
    (totalUsage / usageStats.maxAllowedLimit) * 100
  )

  return (
    <section className='mb-8 w-full'>
      <h2 className='text-2xl font-semibold mb-4'>Usage Statistics</h2>
      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 w-full'>
        <div className='bg-white p-4 rounded-lg shadow-md flex flex-col items-center w-full'>
          <h3 className='text-lg font-semibold mb-2'>Minutes Recorded</h3>
          <div className='w-16 h-16 sm:w-20 sm:h-20 md:w-24 md:h-24'>
            <CircularProgressbar
              value={usageStats.recordingSeconds}
              maxValue={usageStats.maxAllowedLimit}
              text={`${convertSecondsToMinutes(
                usageStats.recordingSeconds
              )} mins`}
              styles={buildStyles({
                textColor: 'black',
                pathColor: 'green',
                trailColor: 'lightgray',
              })}
            />
          </div>
        </div>
        <div className='bg-white p-4 rounded-lg shadow-md flex flex-col items-center w-full'>
          <h3 className='text-lg font-semibold mb-2'>Minutes Uploaded</h3>
          <div className='w-16 h-16 sm:w-20 sm:h-20 md:w-24 md:h-24'>
            <CircularProgressbar
              value={usageStats.uploadSeconds}
              maxValue={usageStats.maxAllowedLimit}
              text={`${convertSecondsToMinutes(usageStats.uploadSeconds)} mins`}
              styles={buildStyles({
                textColor: 'black',
                pathColor: 'blue',
                trailColor: 'lightgray',
              })}
            />
          </div>
        </div>
        <div className='bg-white p-4 rounded-lg shadow-md flex flex-col items-center w-full'>
          <h3 className='text-lg font-semibold mb-2'>Total Usage</h3>
          <div className='w-16 h-16 sm:w-20 sm:h-20 md:w-24 md:h-24'>
            <CircularProgressbar
              value={totalUsagePercentage || 0}
              maxValue={100}
              text={`${totalUsagePercentage || 0}%`}
              styles={buildStyles({
                textColor: 'black',
                pathColor: 'orange',
                trailColor: 'lightgray',
              })}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default UsageStatistics
