import React, { useState, useEffect } from 'react'
import axios from 'axios'
import useAuth from '../hooks/useAuth'
import { convertSecondsToMinutes } from '../utils/secondsToMinutes'
import dayjs from 'dayjs'

const ProfilePage = () => {
  const { setUser } = useAuth()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [cancelStatus, setCancelStatus] = useState({ status: '', message: '' })

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    plan: '',
    password: '',
  })
  const [userInfo, setUserInfo] = useState({})
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    const fetchUserProfile = async () => {
      setLoading(true)
      setError(false)
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/user/me`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('reekapToken')}`,
            },
          }
        )
        const { name, email, phone, subscription } = response.data
        setUserInfo(response.data)
        setFormData({
          name,
          email,
          phone,
          plan: subscription.planId.name,
          password: '',
        })
        setLoading(false)
      } catch (error) {
        setLoading(false)
        setError(true)
        console.error('Error fetching user profile:', error)
      }
    }
    fetchUserProfile()
  }, [])

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleCancelSubscription = async () => {
    try {
      setCancelStatus({ status: '', message: '' })
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/payments/cancel-subscription`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('reekapToken')}`,
          },
        }
      )
      setCancelStatus({
        status: 'success',
        message: 'Subscription cancelled successfully',
      })
    } catch (error) {
      setCancelStatus({
        status: 'error',
        message:
          error.response.data.message || 'Could not cancel your subscription',
      })
    }
  }

  const handleSaveChanges = async () => {
    setSuccessMessage('')
    setErrorMessage('')
    const updatedData = {
      name: formData.name,
      password: formData.password,
    }
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/user/me`,
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('reekapToken')}`,
          },
        }
      )
      setUser(response.data)
      setSuccessMessage('Profile updated successfully!')
    } catch (error) {
      console.error('Error updating profile:', error)
      setErrorMessage('Failed to update profile.')
    }
  }

  if (loading) {
    return (
      <div className='w-screen h-screen flex items-center justify-center'>
        <div className='loading-spinner'></div>
      </div>
    )
  }

  if (error) {
    return (
      <div className='w-screen h-screen flex items-center justify-center'>
        <h2 className='text-2xl'>An Error Occurred, Please Try Again Later</h2>
      </div>
    )
  }

  return (
    <div className='max-w-4xl mx-auto p-8 bg-white shadow-xl rounded-lg mt-12 space-y-8'>
      <div>
        <h2 className='text-2xl font-bold mb-4'>Your Current Subscription</h2>
        {cancelStatus.status === 'success' && (
          <div className='bg-green-100 text-green-700 p-3 rounded-md mb-4'>
            {cancelStatus.message}
          </div>
        )}
        {cancelStatus.status === 'error' && (
          <div className='bg-red-100 text-red-700 p-3 rounded-md mb-4'>
            {cancelStatus.message}
          </div>
        )}
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
          <div className='flex flex-col gap-2'>
            <span className='font-semibold text-gray-700'>Plan:</span>
            <span className='text-gray-500'>
              {userInfo.subscription.planId.name}
            </span>
          </div>
          <div className='flex flex-col gap-2'>
            <span className='font-semibold text-gray-700'>Plan Limits:</span>
            <span className='text-gray-500'>
              {convertSecondsToMinutes(
                userInfo.subscription.planId.allowedLimit +
                  userInfo.usage.bonusLimit
              )}{' '}
              minutes
            </span>
          </div>
          <div className='flex flex-col gap-2'>
            <span className='font-semibold text-gray-700'>Used Limit:</span>
            <span className='text-gray-500'>
              {convertSecondsToMinutes(userInfo.usage.currentPeriodUsage)}{' '}
              minutes
            </span>
          </div>
          <div className='flex flex-col gap-2'>
            <span className='font-semibold text-gray-700'>
              Remaining Limit:
            </span>
            <span className='text-gray-500'>
              {convertSecondsToMinutes(
                userInfo.subscription.planId.allowedLimit +
                  userInfo.usage.bonusLimit -
                  userInfo.usage.currentPeriodUsage
              )}{' '}
              minutes
            </span>
          </div>
          <div className='flex flex-col gap-2'>
            <span className='font-semibold text-gray-700'>Plan Expires:</span>
            <span className='text-gray-500'>
              {dayjs(userInfo.subscription.expiresAt).format('MMM DD, YYYY')}
            </span>
          </div>
        </div>
        <button
          onClick={handleCancelSubscription}
          className='mt-4 bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600'
        >
          Cancel Subscription
        </button>
      </div>

      <div>
        <h2 className='text-2xl font-bold mb-4'>Profile</h2>
        {successMessage && (
          <div className='bg-blue-100 text-blue-700 p-3 rounded-md mb-4'>
            {successMessage}
          </div>
        )}
        {errorMessage && (
          <div className='bg-red-100 text-red-700 p-3 rounded-md mb-4'>
            {errorMessage}
          </div>
        )}
        <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
          <div className='flex flex-col'>
            <label className='mb-2 font-semibold text-gray-700' htmlFor='name'>
              Name
            </label>
            <input
              type='text'
              id='name'
              name='name'
              value={formData.name}
              onChange={handleInputChange}
              className='px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500'
            />
          </div>
          <div className='flex flex-col'>
            <label className='mb-2 font-semibold text-gray-700' htmlFor='email'>
              Email
            </label>
            <input
              type='email'
              id='email'
              name='email'
              value={formData.email}
              onChange={handleInputChange}
              readOnly
              className='px-4 py-2 border rounded-lg bg-gray-100 cursor-not-allowed'
            />
          </div>
          <div className='flex flex-col'>
            <label className='mb-2 font-semibold text-gray-700' htmlFor='phone'>
              Phone
            </label>
            <input
              type='tel'
              id='phone'
              name='phone'
              value={formData.phone}
              onChange={handleInputChange}
              readOnly
              className='px-4 py-2 border rounded-lg bg-gray-100 cursor-not-allowed'
            />
          </div>
          <div className='flex flex-col'>
            <label className='mb-2 font-semibold text-gray-700' htmlFor='plan'>
              Plan
            </label>
            <input
              type='text'
              id='plan'
              name='plan'
              value={formData.plan}
              readOnly
              className='px-4 py-2 border rounded-lg bg-gray-100 cursor-not-allowed'
            />
          </div>
          <div className='flex flex-col'>
            <label
              className='mb-2 font-semibold text-gray-700'
              htmlFor='password'
            >
              New Password
            </label>
            <input
              type='password'
              id='password'
              name='password'
              value={formData.password}
              onChange={handleInputChange}
              className='px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500'
            />
          </div>
        </div>
        <button
          onClick={handleSaveChanges}
          className='mt-6 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600'
        >
          Save Changes
        </button>
      </div>
    </div>
  )
}

export default ProfilePage
