import React, { useState } from 'react';
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const VerifyEmail = () => {
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const { user, setUser } = useAuth();

  const API_BASE_URL = process.env.REACT_APP_API_URL;

  const handleChange = (element, index) => {
    const value = element.value;
    if (!isNaN(value) && value !== '') {
      const newOtp = [...otp];
      newOtp[index] = value;

      // Update OTP state
      setOtp(newOtp);

      // Move focus to the next input
      if (index < otp.length - 1) {
        element.nextSibling && element.nextSibling.focus();
      }
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData('text').slice(0, 6).split('');
    if (pasteData.every((char) => !isNaN(char))) {
      setOtp([...pasteData, ...new Array(6 - pasteData.length).fill('')]);
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
      if (otp[index] === '') {
        if (index > 0) {
          e.target.previousSibling && e.target.previousSibling.focus();
        }
      } else {
        const newOtp = [...otp];
        newOtp[index] = '';
        setOtp(newOtp);
      }
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      setIsLoading(true);
      const otpCode = otp.join('');
      const response = await axios.post(
        `${API_BASE_URL}/api/auth/verify-email`,
        { userId: user?.id, otp: otpCode }
      );
      if (response.data && response.status === 200) {
        setMessage(response.data.message);
        setUser((prevUser) => ({ ...prevUser, isEmailVerified: true }));
      } else {
        throw new Error('Verification response issue');
      }
      setIsLoading(false);
    } catch (err) {
      console.error('Error during email verification:', err);
      setError(err.response?.data?.error || 'Failed to verify email.');
      setMessage('');
      setIsLoading(false);
    }
  };

  if (user?.id && user?.isEmailVerified) {
    return <Navigate to='/verify-phone' />;
  }

  if (!user?.id && user?.isEmailVerified !== false) {
    return <Navigate to='/register' />;
  }

  return (
    <div className='flex h-[100vh] items-center justify-center bg-gray-100 w-full px-4 md:px-0'>
      <div className='bg-white border shadow-lg rounded-lg flex flex-col items-center w-full md:w-1/2 lg:w-1/3 p-4 md:p-8'>
        <h1 className='text-2xl md:text-3xl font-bold mb-4 md:mb-6 text-center'>Verify Email</h1>
        <p className='mb-4 md:mb-6 text-center'>Please enter the OTP sent to your email to verify.</p>
        {error && <div className='text-red-600 text-center'>{error}</div>}
        {message && <div className='text-green-600 text-center'>{message}</div>}
        <form onSubmit={onSubmit} className='w-full flex flex-col items-center'>
          <div className='flex justify-center mb-4 md:mb-8 space-x-2'>
            {otp.map((data, index) => (
              <input
                className='w-8 md:w-10 h-8 md:h-10 text-center border-2 border-blue-500 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500'
                type='text'
                name='otp'
                maxLength='1'
                key={index}
                value={data}
                onChange={(e) => handleChange(e.target, index)}
                onFocus={(e) => e.target.select()}
                onKeyDown={(e) => handleKeyDown(e, index)}
                onPaste={(e) => handlePaste(e)}
                required
              />
            ))}
          </div>
          <button
            type='submit'
            className='mt-4 md:mt-6 bg-blue-500 text-white py-2 px-4 rounded-md w-full md:w-auto hover:bg-blue-600 transition-colors duration-200 ease-in-out'
            disabled={isLoading}
          >
            {isLoading ? <div className='loading'></div> : 'Verify Email'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default VerifyEmail;
