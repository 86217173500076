import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Sidebar from '../components/Sidebar';
import UsageStatistics from '../components/UsageStatistics';
import QuickActions from '../components/QuickActions';

const DashboardPage = () => {
  const [usageStats] = useState({
    recordings: 40,
    transcriptions: 60,
    summaries: 80,
    minutes: 50,
    maxRecordings: 100,
    maxTranscriptions: 100,
    maxSummaries: 100,
    maxMinutes: 100,
  });

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [userName, setUserName] = useState('');
  const [userPlan, setUserPlan] = useState(''); // State for user plan information
  const [remainingMinutes, setRemainingMinutes] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/user/me`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('reekapToken')}`,
            },
          }
        );
        const { name, subscription, usage } = res.data;
        setUserName(name);
        setUserPlan(subscription.planId.name); // Set the current plan name
        const allowedMinutes = subscription.planId.allowedLimit + usage.bonusLimit;
        const usedMinutes = usage.currentPeriodUsage;
        setRemainingMinutes(allowedMinutes - usedMinutes); // Calculate remaining minutes
      } catch (err) {
        console.error('Error fetching user data:', err);
      }
    };

    fetchUserData();
  }, []);

  return (
    <div className='flex flex-col min-h-screen bg-gray-100'>
      <div className='flex flex-1'>
        {/* Sidebar */}
        <Sidebar
          isOpen={sidebarOpen}
          toggleSidebar={() => setSidebarOpen(!sidebarOpen)}
        />

        {/* Dashboard Content */}
        <div
          className={`flex-1 p-4 md:p-8 transition-transform duration-300 ${
            sidebarOpen ? 'transform md:translate-x-0' : 'md:ml-12'
          } min-w-0`}
        >
          {/* Top Section: Welcome and Status */}
          <section className='flex flex-col md:flex-row justify-between items-start md:items-center mb-8 space-y-4 md:space-y-0'>
            {/* User Welcome Message */}
            <div className='flex-1'>
              <h1 className='text-3xl font-bold mb-2 text-[#2c3e50]'>
                Hi, {userName}!
              </h1>
            </div>

            {/* User Current Plan Status */}
            <div className='w-full md:w-auto bg-white p-4 rounded-lg shadow-md text-right'>
              <h2 className='text-lg font-semibold text-[#2c3e50]'>Your Status</h2>
              <p className='text-gray-600'>
                You are currently on the <span className='font-medium'>{userPlan}</span> plan.
              </p>
              {userPlan === 'Free' ? (
                <>
                  <p className='text-gray-600 mt-2'>
                    You have <span className='font-semibold'>{remainingMinutes} minutes</span> remaining. Consider upgrading for more minutes and additional features.
                  </p>
                  <div className='mt-2'>
                    <button
                      className='px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors duration-200 ease-in-out'
                      onClick={() => navigate('/plans')}
                    >
                      Upgrade Plan
                    </button>
                  </div>
                </>
              ) : (
                <p className='text-gray-600 mt-2'>
                  You have <span className='font-semibold'>{remainingMinutes} minutes</span> remaining for this month.
                </p>
              )}
            </div>
          </section>

          {/* Main Content Section */}
          <div className='space-y-8'>
            {/* Usage Statistics */}
            <UsageStatistics usageStats={usageStats} />

            {/* Quick Actions */}
            <QuickActions navigate={navigate} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
