import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaRegFileAudio, FaBell, FaClipboardList, FaHome, FaSignOutAlt, FaListAlt } from 'react-icons/fa';
import useAuth from '../hooks/useAuth'; // Import useAuth to get user data

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const location = useLocation();
  const { user, logout } = useAuth(); // Access the user data from the auth context

  const isActive = (path) => location.pathname === path;

  const handleLogout = () => {
    logout(); // Call the logout function to clear user data
  };

  return (
    <aside
      className={`bg-[#2c3e50] text-white w-64 py-8 fixed inset-y-0 left-0 transform ${
        isOpen ? 'translate-x-0' : '-translate-x-full'
      } transition-transform duration-300 ease-in-out md:relative md:translate-x-0`}
    >
      <div className="px-6">
        {/* Navigation Links without Profile and Plan Info */}
        <nav>
          <ul>
            <li className="mb-4">
              <Link
                to="/dashboard"
                className={`flex items-center p-2 rounded ${
                  isActive('/dashboard') ? 'bg-[#38b6ff] text-white' : 'hover:bg-[#38b6ff] hover:text-white'
                }`}
              >
                <FaHome className="mr-2" />
                Dashboard
              </Link>
            </li>
            <li className="mb-4">
              <Link
                to="/notifications"
                className={`flex items-center p-2 rounded ${
                  isActive('/notifications') ? 'bg-[#38b6ff] text-white' : 'hover:bg-[#38b6ff] hover:text-white'
                }`}
              >
                <FaBell className="mr-2" />
                Notifications
              </Link>
            </li>
            <li className="mb-4">
              <Link
                to="/support"
                className={`flex items-center p-2 rounded ${
                  isActive('/support') ? 'bg-[#38b6ff] text-white' : 'hover:bg-[#38b6ff] hover:text-white'
                }`}
              >
                <FaRegFileAudio className="mr-2" />
                Support/Help
              </Link>
            </li>
            <li className="mb-4">
              <Link
                to="/plans"
                className={`flex items-center p-2 rounded ${
                  isActive('/plans') ? 'bg-[#38b6ff] text-white' : 'hover:bg-[#38b6ff] hover:text-white'
                }`}
              >
                <FaListAlt className="mr-2" />
                Plans
              </Link>
            </li>
            <li className="mb-4">
              <Link
                to="/profile"
                className={`flex items-center p-2 rounded ${
                  isActive('/profile') ? 'bg-[#38b6ff] text-white' : 'hover:bg-[#38b6ff] hover:text-white'
                }`}
              >
                <FaClipboardList className="mr-2" />
                Profile
              </Link>
            </li>
          </ul>
        </nav>
      </div>

      {/* Log Out Button */}
      {user && (
        <div className="absolute bottom-4 left-0 w-full px-6">
          <button
            onClick={handleLogout}
            className="flex items-center justify-center space-x-2 bg-red-500 hover:bg-red-600 w-full py-2 rounded transition-colors duration-200 ease-in-out"
          >
            <FaSignOutAlt className="mr-2" />
            <span>Logout</span>
          </button>
        </div>
      )}

      {/* Close Sidebar Button for Mobile View */}
      <button
        className="absolute top-4 right-4 md:hidden text-white focus:outline-none"
        onClick={toggleSidebar}
      >
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
        </svg>
      </button>
    </aside>
  );
};

export default Sidebar;
