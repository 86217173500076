import React, { useState } from 'react';
import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const VerifyPhone = () => {
  const [phone, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState('+1'); // Default country code
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [showOtpSection, setShowOtpSection] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false); // State for checkbox
  const { user, login } = useAuth();
  const navigate = useNavigate();

  const API_BASE_URL = process.env.REACT_APP_API_URL;

  const handleChange = (element, index) => {
    const value = element.value;
    if (!isNaN(value) && value !== '') {
      const newOtp = [...otp];
      newOtp[index] = value;

      // Update OTP state
      setOtp(newOtp);

      // Move focus to the next input
      if (index < otp.length - 1) {
        element.nextSibling && element.nextSibling.focus();
      }
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData('text').slice(0, 6).split('');
    if (pasteData.every((char) => !isNaN(char))) {
      setOtp([...pasteData, ...new Array(6 - pasteData.length).fill('')]);
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
      if (otp[index] === '') {
        if (index > 0) {
          e.target.previousSibling && e.target.previousSibling.focus();
        }
      } else {
        const newOtp = [...otp];
        newOtp[index] = '';
        setOtp(newOtp);
      }
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    if (!isAgreed) {
      setError('You must agree to receive text messages.');
      return;
    }

    try {
      setIsLoading(true);
      const response = await axios.post(
        `${API_BASE_URL}/api/auth/verify-phone`,
        { phone: countryCode + phone, userId: user?.id }
      );
      if (response && response.data) {
        setMessage(response.data.message);
        setShowOtpSection(true);
      } else {
        setError('Unexpected response from the server.');
      }
      setIsLoading(false);
    } catch (err) {
      console.error('Error sending verification code:', err);
      setError(err.response?.data?.error || 'Failed to send verification code.');
      setIsLoading(false);
    }
  };

  const onOtpSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/auth/verify-phone-otp`,
        { otp: otp.join(''), userId: user?.id }
      );
      if (response && response.data) {
        setMessage(response.data.message);
        login(response.data);
        navigate('/dashboard');
      } else {
        setError('Unexpected response from the server.');
      }
    } catch (err) {
      console.error('Error verifying OTP:', err);
      setError(err.response?.data?.error || 'Failed to verify phone number.');
      setMessage('');
    }
  };

  if (!user?.id) {
    return <Navigate to='/register' />;
  }

  // Redirect to email verification if email is not verified
  if (user?.id && !user?.isEmailVerified) {
    return <Navigate to='/verify-email' />;
  }
  if (user?.id && user?.isPhoneVerified) {
    return <Navigate to='/dashboard' />;
  }

  return (
    <div className="flex h-[100vh] items-center justify-center bg-gray-100 w-full px-4 md:px-0">
      <div className="bg-white border shadow-lg rounded-lg flex flex-col items-center w-full sm:w-3/4 md:w-1/2 lg:w-1/3 p-4 md:p-8">
        <div className="p-4 sm:p-6 md:p-8 w-full">
          <h1 className="text-xl sm:text-2xl md:text-3xl font-bold text-center text-gray-800 mb-4">
            Verify Phone
          </h1>
          <p className="text-center text-gray-600 mb-4 md:mb-6">
            Enter your phone number to receive a verification code.
          </p>

          {error && (
            <div className="bg-red-100 text-red-700 p-2 rounded-md mb-4 text-center w-full">
              {error}
            </div>
          )}
          {message && (
            <div className="bg-green-100 text-green-700 p-2 rounded-md mb-4 text-center w-full">
              {message}
            </div>
          )}

          <form onSubmit={onSubmit} className="space-y-4 md:space-y-6 w-full mb-4">
            <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-2">
              <select
                className="w-full sm:w-1/4 p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                value={countryCode}
                onChange={(e) => setCountryCode(e.target.value)}
                required
              >
                <option value="+1">+1</option>
                <option value="+44">+44</option>
                <option value="+91">+91</option>
                <option value="+234">+234</option>
              </select>
              <input
                className="w-full sm:w-3/4 p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                type="tel"
                placeholder="Enter your phone number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
            </div>
            <div className="flex items-center justify-center">
              <input
                type="checkbox"
                id="agree"
                className="mr-2"
                checked={isAgreed}
                onChange={(e) => setIsAgreed(e.target.checked)}
                required
              />
              <label htmlFor="agree" className="text-gray-600">
                I agree to receive text messages from Reekap by RavenZone.
              </label>
            </div>
            <div className="flex justify-center">
              <button
                className="w-full md:w-auto px-6 bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="flex justify-center items-center">
                    <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white"></div>
                  </div>
                ) : (
                  "Send Verification Code"
                )}
              </button>
            </div>
          </form>

          {showOtpSection && (
            <div className="mt-4 md:mt-8 w-full">
              <p className="text-center text-gray-600 mb-4">
                Enter the OTP sent to your phone.
              </p>
              <form onSubmit={onOtpSubmit} className="space-y-4 md:space-y-6 w-full">
                <div className="flex justify-center space-x-2">
                  {otp.map((data, index) => (
                    <input
                      key={index}
                      className="w-8 md:w-10 h-8 md:h-10 text-center text-xl font-semibold border-2 border-blue-500 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      type="text"
                      name="otp"
                      maxLength="1"
                      value={data}
                      onChange={(e) => handleChange(e.target, index)}
                      onKeyDown={(e) => handleKeyDown(e, index)}
                      onPaste={(e) => handlePaste(e)}
                      onFocus={(e) => e.target.select()}
                      required
                    />
                  ))}
                </div>
                <div className="flex justify-center">
                  <button
                    className="w-full md:w-auto px-6 bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <div className="flex justify-center items-center">
                        <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white"></div>
                      </div>
                    ) : (
                      "Verify OTP"
                    )}
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VerifyPhone;
