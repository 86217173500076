import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Bars3Icon, XMarkIcon, BellIcon, ArrowRightOnRectangleIcon } from '@heroicons/react/24/outline';
import useAuth from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import ProfileIcon from '../components/ProfileIcon';

const Header = () => {
  const { user, logout } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <header className="bg-[#2c3e50] p-4">
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo Section */}
        <Link to="/">
          <img src="/assets/images/reekap_logo.svg" alt="Reekap Logo" className="h-12" />
        </Link>

        {/* Desktop Navigation */}
        <nav className="hidden md:flex items-center space-x-4">
          {/* Conditional Link for Plans/Pricing */}
          <Link
            to={user ? "/plans" : "/pricing"} // If user is logged in, navigate to "/plans", else navigate to "/pricing"
            className="text-white hover:text-[#38b6ff] font-bold py-2 px-4"
          >
            {user ? "Plans" : "Pricing"}
          </Link>

          {/* If User is Logged In, Show These Options */}
          {user ? (
            <div className="flex items-center space-x-4">
              {/* Dashboard Link */}
              <Link to="/dashboard" className="text-white hover:text-[#38b6ff]">
                Dashboard
              </Link>

              {/* Notifications Icon */}
              <Link to="/notifications" className="text-white hover:text-[#38b6ff] relative">
                <BellIcon className="w-6 h-6" />
              </Link>

              {/* Profile Icon */}
              <Link to="/profile" className="text-white hover:text-[#38b6ff]">
                {user.profileImage ? (
                  <img
                    src={user.profileImage}
                    alt="Profile"
                    className="w-10 h-10 rounded-full border-2 border-white"
                  />
                ) : (
                  <ProfileIcon name={user.name} />
                )}
              </Link>

              {/* Visible Logout Button */}
              <button
                onClick={handleLogout}
                className="flex items-center space-x-2 text-white bg-red-500 hover:bg-red-600 py-2 px-4 rounded transition-colors duration-200 ease-in-out"
              >
                <ArrowRightOnRectangleIcon className="w-5 h-5" />
                <span>Logout</span>
              </button>
            </div>
          ) : (
            // If User is Not Logged In, Show Login and Register Buttons
            <div className="flex space-x-4">
              <Link to="/login" className="bg-white text-[#2c3e50] font-bold py-2 px-4 rounded hover:bg-gray-100">
                Login
              </Link>
              <Link to="/register" className="bg-[#38b6ff] text-white font-bold py-2 px-4 rounded hover:bg-[#2980b9]">
                Start For Free
              </Link>
            </div>
          )}
        </nav>

        {/* Mobile Hamburger Menu Button */}
        <div className="md:hidden">
          <button onClick={toggleMenu} className="text-white focus:outline-none">
            {isOpen ? <XMarkIcon className="w-6 h-6" /> : <Bars3Icon className="w-6 h-6" />}
          </button>
        </div>
      </div>


    {/* Mobile Navigation Menu */}
{isOpen && (
  <nav className="md:hidden bg-[#34495e] mt-4 flex flex-col items-center space-y-4 py-4">
    <Link
      to={user ? "/plans" : "/pricing"} // Conditional Link for Mobile View as well
      className="block text-white hover:text-[#38b6ff] font-bold px-4 py-2 text-center"
    >
      {user ? "Plans" : "Pricing"}
    </Link>
    {user ? (
      <>
        <Link to="/dashboard" className="block text-white hover:text-[#38b6ff] px-4 py-2 text-center">
          Dashboard
        </Link>
        <Link to="/notifications" className="block text-white hover:text-[#38b6ff] px-4 py-2 text-center">
          Notifications
        </Link>
        <Link to="/profile" className="block text-white hover:text-[#38b6ff] px-4 py-2 text-center">
          Profile
        </Link>
        <button
          onClick={handleLogout}
          className="block w-full text-white hover:text-[#38b6ff] px-4 py-2 text-center font-bold"
        >
          Logout
        </button>
      </>
    ) : (
      <>
        <Link to="/login" className="block bg-white text-[#2c3e50] font-bold py-2 px-4 rounded hover:bg-gray-100 text-center my-2">
          Login
        </Link>
        <Link to="/register" className="block bg-[#38b6ff] text-white font-bold py-2 px-4 rounded hover:bg-[#2980b9] text-center my-2">
          Start Free Trial
        </Link>
      </>
    )}
  </nav>
)}

    </header>
  );
};

export default Header;
