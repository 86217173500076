import React from 'react';
import { Link } from 'react-router-dom';


const LandingPage = () => {
  // Removed unused togglePricing function

  return (
    <div className="bg-gray-100 min-h-screen">
      {/* Hero Section */}
      <div className="bg-gradient-to-r from-[#2c3e50] to-[#34495e] py-20 px-4">
        <div className="max-w-7xl mx-auto text-center">
          <h1 className="text-4xl md:text-6xl font-bold text-white mb-10">
            Unlock the Full Potential of Your Meetings!
          </h1>
          <p className="text-lg md:text-xl text-gray-200 mb-8">
            Experience smarter meetings with AI-powered transcription and summaries. Download the desktop app for enhanced quality and seamless recording.
          </p>
          <div className="flex justify-center space-x-4">
            {/* Windows Download Button */}
            <Link
              to="/download-windows"
              className="flex items-center space-x-2 bg-[#38b6ff] text-white py-3 px-6 rounded-lg font-semibold hover:bg-[#2980b9] transition duration-300"
            >
              <img src="/assets/images/windowsicon.png" alt="Windows Icon" className="w-6 h-6" />
              <span>Download for Windows</span>
            </Link>

            {/* Mac Download Button */}
            <Link
              to="/download-mac"
              className="flex items-center space-x-2 bg-[#38b6ff] text-white py-3 px-6 rounded-lg font-semibold hover:bg-[#2980b9] transition duration-300"
            >
              <img src="/assets/images/appleicon.png" alt="Mac Icon" className="w-6 h-6" />
              <span>Download for Mac</span>
            </Link>
          </div>
          <p className="text-sm text-gray-300 mt-4">
            Prefer the web? <Link to="/register" className="text-[#38b6ff] underline">Use the web version</Link>
          </p>
          <div className="mt-12">
            <img
              src="/assets/images/app_dashboard.png"
              alt="Reekap in action"
              className="w-full rounded-lg shadow-lg"
            />
          </div>
        </div>
      </div>

 {/* Desktop Advantages */}
 <div className="bg-white py-16 px-4">
  <div className="max-w-7xl mx-auto text-center">
    <h2 className="text-4xl font-bold text-[#2c3e50] mb-8">Why Choose the Desktop App?</h2>
    <p className="text-lg text-gray-600 mb-12">
      Unlock the full potential of Reekap with our desktop app. Get the highest quality recordings and screen captures, optimized for desktop.
    </p>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
      <div className="bg-white rounded-lg shadow-md p-8 border border-[#38b6ff] transition-all duration-300 hover:-translate-y-1 hover:shadow-xl">
        <img src="../assets/images/audio-quality-icon.png" alt="Enhanced Audio Quality" className="w-16 h-16 mb-4 mx-auto" />
        <h3 className="text-xl font-semibold mb-4 text-[#2c3e50]">Enhanced Audio Quality</h3>
        <p className="text-gray-600">Capture meetings with superior audio quality for clearer playback.</p>
      </div>
      <div className="bg-white rounded-lg shadow-md p-8 border border-[#38b6ff] transition-all duration-300 hover:-translate-y-1 hover:shadow-xl">
        <img src="../assets/images/screen-recording-icon.png" alt="High-Quality Screen Recording" className="w-16 h-16 mb-4 mx-auto" />
        <h3 className="text-xl font-semibold mb-4 text-[#2c3e50]">High-Quality Screen Recording</h3>
        <p className="text-gray-600">Record your screen and audio together to provide additional context.</p>
      </div>
      <div className="bg-white rounded-lg shadow-md p-8 border border-[#38b6ff] transition-all duration-300 hover:-translate-y-1 hover:shadow-xl">
        <img src="../assets/images/no-browser-icon.png" alt="No Browser Limits" className="w-16 h-16 mb-4 mx-auto" />
        <h3 className="text-xl font-semibold mb-4 text-[#2c3e50]">No Browser Limits</h3>
        <p className="text-gray-600">Avoid browser restrictions and get better performance with a native app.</p>
      </div>
    </div>
  </div>
</div>


      {/* Feature Highlights */}
      <div className="max-w-7xl mx-auto py-16 px-4">
        <h2 className="text-3xl font-bold text-[#2c3e50] mb-12 text-center">
          What You Get with Reekap — For Free
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <div className="bg-white rounded-lg shadow-md p-8 border border-[#38b6ff] transition-all duration-300 hover:-translate-y-1 hover:shadow-xl">
            <img src="../assets/images/record-icon.svg" alt="Record Meetings" className="w-16 h-16 mb-4" />
            <h3 className="text-xl font-semibold mb-4 text-[#2c3e50]">Record Meetings</h3>
            <p className="text-gray-600">
              Effortlessly capture every detail with one-click recording on any device. No storage limits for your recordings!
            </p>
          </div>
          <div className="bg-white rounded-lg shadow-md p-8 border border-[#38b6ff] transition-all duration-300 hover:-translate-y-1 hover:shadow-xl">
            <img src="../assets/images/transcribe-icon.svg" alt="Transcribe Audio" className="w-16 h-16 mb-4" />
            <h3 className="text-xl font-semibold mb-4 text-[#2c3e50]">Transcribe Audio</h3>
            <p className="text-gray-600">
              Convert speech to text with industry-leading accuracy powered by the latest AI technology.
            </p>
          </div>
          <div className="bg-white rounded-lg shadow-md p-8 border border-[#38b6ff] transition-all duration-300 hover:-translate-y-1 hover:shadow-xl">
            <img src="../assets/images/summarize-icon.svg" alt="Generate Summaries" className="w-16 h-16 mb-4" />
            <h3 className="text-xl font-semibold mb-4 text-[#2c3e50]">Generate Summaries</h3>
            <p className="text-gray-600">
              Get concise, AI-generated summaries highlighting key points and action items.
            </p>
          </div>
          <div className="bg-white rounded-lg shadow-md p-8 border border-[#38b6ff] transition-all duration-300 hover:-translate-y-1 hover:shadow-xl">
            <img src="../assets/images/minutes-icon.svg" alt="Create Meeting Minutes" className="w-16 h-16 mb-4" />
            <h3 className="text-xl font-semibold mb-4 text-[#2c3e50]">Create Meeting Minutes</h3>
            <p className="text-gray-600">
              Automatically generate structured meeting minutes for easy follow-up.
            </p>
          </div>
        </div>
      </div>

      {/* How It Works */}
      <div className="bg-gradient-to-r from-[#2c3e50] to-[#34495e] py-16 px-4">
  <div className="max-w-7xl mx-auto text-center">
    <h2 className="text-3xl font-bold text-white mb-12">How Reekap Works</h2>
    <div className="flex flex-col md:flex-row justify-between items-center">
      <div className="md:w-1/4 mb-8 md:mb-0">
        <img src="../assets/images/download-web.png" alt="Download or Use Web" className="w-16 h-16 mb-4 mx-auto" />
        <h3 className="text-xl font-semibold text-white">Download or Use Web</h3>
        <p className="text-gray-300">Choose the desktop app for the best experience.</p>
      </div>
      <div className="md:w-1/4 mb-8 md:mb-0">
        <img src="../assets/images/record-upload.png" alt="Record or Upload" className="w-16 h-16 mb-4 mx-auto" />
        <h3 className="text-xl font-semibold text-white">Record or Upload</h3>
        <p className="text-gray-300">Record a live meeting or upload an audio file.</p>
      </div>
      <div className="md:w-1/4 mb-8 md:mb-0">
        <img src="../assets/images/transcribe-summarize.png" alt="Transcribe" className="w-16 h-16 mb-4 mx-auto" />
        <h3 className="text-xl font-semibold text-white">Transcribe & Summarize</h3>
        <p className="text-gray-300">Get accurate transcriptions and clear summaries.</p>
      </div>
      <div className="md:w-1/4">
        <img src="../assets/images/review-share.png" alt="Review and Share" className="w-16 h-16 mb-4 mx-auto" />
        <h3 className="text-xl font-semibold text-white">Review & Share</h3>
        <p className="text-gray-300">Save, share, or export your meeting content easily.</p>
      </div>
    </div>
  </div>
</div>


    {/* CTA Section */}
<div className="bg-white py-16 px-4">
  <div className="max-w-3xl mx-auto text-center">
    <h2 className="text-3xl font-bold text-[#2c3e50] mb-8">
      Ready to Revolutionize Your Meetings?
    </h2>
    <p className="text-xl text-gray-700 mb-8">
      Join thousands of teams already using Reekap to boost productivity and clarity.
    </p>
    <Link
      to="/register"
      className="bg-[#38b6ff] text-white py-3 px-8 rounded-lg font-semibold text-lg hover:bg-blue-600 transition-all duration-300 ease-in-out hover:-translate-y-0.5 hover:shadow-md focus:outline-none focus:ring-2 focus:ring-[#38b6ff] focus:ring-opacity-50"
    >
      Start For Free
    </Link>
  </div>
</div>

    </div>
  );
};

export default LandingPage;
