import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { convertSecondsToMinutes } from '../utils/secondsToMinutes';

const PricingPage = () => {
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/plans`);
        setPlans(response.data);
      } catch (error) {
        console.error('Error fetching plans:', error);
      }
    };

    fetchPlans();
  }, []);

  return (
    <div className='container mx-auto p-4'>
      {/* Header Section */}
      <section className='text-center my-8'>
        <h1 className='text-4xl font-bold text-black mb-4'>
          Start Free and Upgrade as You Grow
        </h1>
        <p className='text-lg text-gray-600'>
          Begin with our forever-free plan and unlock more features as your needs grow. No credit card required.
        </p>
        {/* Conditional CTA Button for non-logged in users */}
        <Link
          to='/register'
          className='inline-block bg-[#38b6ff] text-white font-bold py-2 px-6 rounded mt-6 hover:bg-blue-600 transition duration-200'
        >
          Get Started for Free
        </Link>
      </section>

      {/* Plans Section */}
      <section className='my-12'>
        <h2 className='text-3xl font-bold text-center text-black mb-8'>
          Our Plans
        </h2>
        <div className='grid grid-cols-1 md:grid-cols-3 gap-8'>
          {plans.map((plan) => (
            <div
              key={plan._id}
              className={`border rounded-lg p-6 shadow-md hover:shadow-lg transition duration-300 ${
                plan.name.toLowerCase() === 'free' ? 'border-[#38b6ff]' : 'border-gray-300'
              }`}
            >
              <h3 className='text-2xl font-semibold text-black mb-4'>{plan.name}</h3>
              <p className='text-gray-600 mb-4'>{plan.description}</p>
              <p className='text-gray-700 font-medium'>
                Recording Minutes: {convertSecondsToMinutes(plan.allowedLimit)}
              </p>
              <p className='text-lg font-bold mt-4'>${plan.price} / month</p>
              {/* Display 'Free Plan' for the free option */}
              {plan.name.toLowerCase() === 'free' ? (
                <Link
                  to='/register'
                  className='block bg-[#38b6ff] text-white font-bold py-2 px-4 rounded mt-6 hover:bg-blue-600 transition duration-200 text-center'
                >
                  Get Started for Free
                </Link>
              ) : (
                <Link
                  to='/register'
                  className='block bg-[#38b6ff] text-white font-bold py-2 px-4 rounded mt-6 hover:bg-blue-600 transition duration-200 text-center'
                >
                  Upgrade Now
                </Link>
              )}
            </div>
          ))}
        </div>
      </section>

      {/* Optional Top-Up Explanation Section */}
      <section className='my-12 text-center'>
        <h2 className='text-2xl font-bold text-black mb-4'>Need More Minutes?</h2>
        <p className='text-lg text-gray-600'>
          For paid plans, we offer top-up options. If you run out of minutes before your billing cycle renews, you can purchase additional recording time at any point from your account settings.
        </p>
      </section>

      {/* Plan Comparison Section */}
      <section className='my-12 bg-gray-50 p-8 rounded-lg shadow-md'>
        <h2 className='text-2xl font-bold text-center mb-6 text-black'>Plan Comparison</h2>
        <div className='overflow-x-auto'>
          <table className='w-full text-left'>
            <thead>
              <tr>
                <th className='py-2 px-4 bg-gray-200'>Feature</th>
                {plans.map((plan) => (
                  <th key={plan._id} className='py-2 px-4 bg-gray-200 text-center'>
                    {plan.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='py-2 px-4'>Recording Minutes</td>
                {plans.map((plan) => (
                  <td key={plan._id} className='py-2 px-4 text-center'>
                    {convertSecondsToMinutes(plan.allowedLimit)}
                  </td>
                ))}
              </tr>
              {/* Add more comparison rows here as needed */}
            </tbody>
          </table>
        </div>
      </section>

      {/* FAQs Section */}
      <section className='my-12'>
        <h2 className='text-2xl font-bold text-center mb-6 text-black'>Frequently Asked Questions</h2>
        <div className='space-y-4'>
          <div className='border-b pb-4'>
            <h3 className='font-semibold text-lg'>What is included in each plan?</h3>
            <p className='text-gray-600'>
              Each plan offers a certain number of recording minutes per month, which can be used for recording and transcribing your meetings. Higher-tier plans come with additional features and more recording minutes.
            </p>
          </div>
          <div className='border-b pb-4'>
            <h3 className='font-semibold text-lg'>Can I switch plans later?</h3>
            <p className='text-gray-600'>
              Yes, you can upgrade or downgrade your plan anytime. Simply go to your account settings and choose a different plan that fits your needs.
            </p>
          </div>
          <div className='border-b pb-4'>
            <h3 className='font-semibold text-lg'>How do I top up my minutes?</h3>
            <p className='text-gray-600'>
              If you're on a paid plan and run out of minutes, you can purchase additional minutes directly from your dashboard under the billing section. Top-up options are available only for paid plans.
            </p>
          </div>
          <div className='border-b pb-4'>
            <h3 className='font-semibold text-lg'>What happens if I run out of minutes on the free plan?</h3>
            <p className='text-gray-600'>
              If you run out of minutes on the free plan, you can upgrade to a paid plan to continue using the service.
            </p>
          </div>
          <div className='border-b pb-4'>
            <h3 className='font-semibold text-lg'>Can I cancel my plan at any time?</h3>
            <p className='text-gray-600'>
              Yes, you can cancel your plan anytime from your account settings. Once canceled, you will continue to have access until the end of your current billing period.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PricingPage;
